import CryptoJS from 'crypto-js';

const AesEncrypt = (props) => {
  let key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AES_REQ_KEY);
  let iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AES_IV);
  const encrypt = CryptoJS.AES.encrypt(JSON.stringify(props), key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  });
  return encrypt.toString();
};

export default AesEncrypt;
