<template>
  <div
    class="flex justify-between items-center text-center py-4 bg-[#f0f0f3] text-[13px] text-[#a0a0a0] drop-shadow-[0_5px_10px_rgba(128,128,128,0.20)] border-t-2 border-white border-opacity-10"
  >
    <div class="basis-1/5">
      <router-link to="/">
      <img src="@/assets/images/symbols_home.png" alt="" class="w-[1.6rem] mx-auto" v-if="$route.path === '/'">
      <img src="@/assets/images/symbols_home_u.png" alt="" class="w-[1.6rem] mx-auto" v-else>
        <div>{{ $t('menuBar.home') }}</div>
      </router-link>
    </div>
    <div class="basis-1/5">
      <router-link to="/point">
        <img src="@/assets/images/symbols_coin.png" alt="" class="w-[1.6rem] mx-auto" v-if="$route.path === '/point'">
      <img src="@/assets/images/symbols_coin_u.png" alt="" class="w-[1.6rem] mx-auto" v-else>
        <div>{{ $t('menuBar.point') }}</div>
      </router-link>
    </div>
    <div class="basis-1/5">
      <router-link to="/transfer">
        <img src="@/assets/images/symbols_transfer.png" alt="" class="w-[1.6rem] mx-auto" v-if="$route.path === '/transfer'">
      <img src="@/assets/images/symbols_transfer_u.png" alt="" class="w-[1.6rem] mx-auto" v-else>
        <div>{{ $t('menuBar.transfer') }}</div>
      </router-link>
    </div>
    <!--<div class="basis-1/5">
      <router-link to="/location">
        <img src="@/assets/images/symbols_map.png" alt="" class="w-[1.6rem] mx-auto" v-if="$route.path === '/location'">
      <img src="@/assets/images/symbols_map_u.png" alt="" class="w-[1.6rem] mx-auto" v-else>
        <div>{{ $t('menuBar.location') }}</div>
      </router-link>
    </div>-->
    <div class="basis-1/5">
      <router-link to="/menu">
        <img src="@/assets/images/symbols_menu.png" alt="" class="w-[1.6rem] mx-auto" v-if="$route.path === '/menu'">
      <img src="@/assets/images/symbols_menu_u.png" alt="" class="w-[1.6rem] mx-auto" v-else>
        <div>{{ $t('menuBar.menu') }}</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuBar",
  data() {
    return {
      activeLink: "",
    };
  },
  methods: {
    setActiveLink(link) {
      this.activeLink = link;
    },
  },
  watch: {
    $route() {
      this.activeLink = this.$route.path;
    },
  },
};
</script>

<style scoped>
.router-link-active {
  color: #4B894A;
}
</style>
<!--c40073 4B894A-->