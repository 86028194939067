<template>
  <div class="w-full h-screen bg-[#4B894A] flex flex-col items-center">
    <!-- network x modal -->
    <!-- <Modal
      @close="modalToggle"
      :modalActive="modalActive"
      v-if="networkNo"
    >
      <div class="px-2 pt-2">
        <p class="text-[12px] mt-2">
          서버와 통신할 수 없습니다.<br />네트워크 상태를 확인해주세요.
        </p>
      </div>
      <div class="flex font-bold">
          <button class="basis-1/2 py-4 text-[#d2d2d2]" @click="modalToggle">
            재시도
          </button>
          <button class="basis-1/2 py-4 text-[#41c1f0]" @click="modalToggle">앱종료</button>
        </div>
    </Modal> -->
    <!-- server 점검 modal -->
    <!-- <Modal
      @close="modalToggle"
      :modalActive="modalActive"
      v-if="systemMaintenance"
    >
      <div class="px-2 pt-2">
        <p class="text-[12px] mt-2">
          서버 점검 중으로 <br/> 서비스를 이용할 수 없습니다.<br/>이용에 불편을 드려 죄송합니다.
        </p>
      </div>
      <div class="flex font-bold">
          <button class="basis-full py-4 text-[#41c1f0]" @click="modalToggle">확인</button>
        </div>
    </Modal> -->
    <!-- app update modal -->
    <!-- <Modal
      @close="modalToggle"
      :modalActive="modalActive"
      v-if="appUpdate"
    >
      <div class="px-2 pt-2">
        <p class="text-[12px] mt-2">
          앱이 업데이트 되었습니다.<br/>스토어에서 최신버전으로<br/>업데이트 해주세요.
        </p>
      </div>
      <div class="flex font-bold">
          <button class="basis-1/2 py-4 text-[#d2d2d2]" @click="modalToggle">
            스토어 이동
          </button>
          <button class="basis-1/2 py-4 text-[#41c1f0]" @click="modalToggle">앱 종료</button>
        </div>
    </Modal> -->
    <!-- suspend account modal -->
    <!-- <Modal
      @close="modalToggle"
      :modalActive="modalActive"
      v-if="suspend"
    >
      <div class="px-2 pt-2">
        <p class="text-[12px] mt-2">
          관리자가 사용을 정지한 계정입니다.<br/>자세한 내용은 고객센터나 관리자에게 문의해주세요.
        </p>
      </div>
      <div class="flex font-bold">
          <button class="basis-full py-4 text-[#41c1f0]" @click="modalToggle">앱 종료</button>
        </div>
    </Modal> -->

    <div class="w-[20%] mt-[30vh]">
      <img src="@/assets/images/logo_w.png" alt="" class="w-full mx-auto" />
    </div>
    <p class="text-[#fdfefe] mt-[5%]">
      <span class="font-bold">R</span>eturn -
      <span class="font-bold">R</span>eward -
      <span class="font-bold">R</span>ecycle
    </p>
    <div class="w-[15%] mt-[5%]">
      <img :src="require(`@/assets/images/loading_${num}.png`)" alt="" />
    </div>
  </div>
</template>

<script>
import Modal from "@/components/AlertModal.vue";
import { ref } from "vue";
export default {
  name: "SplashView",
  data() {
    return {
      num: 1,
      btn_type: "serverFail",
    };
  },
  components: {
    Modal,
  },
  setup() {
    const modalActive = ref(false);
    const modalToggle = () => {
      modalActive.value = !modalActive.value;
    };

    return {
      modalActive,
      modalToggle,
    };
  },
  methods: {
    networkNo() {
      this.networkNo = !this.networkNo;
    },
    systemMaintenance() {
      this.systemMaintenance = !this.systemMaintenance;
    },
    appUpdate() {
      this.appUpdate = !this.appUpdate;
    },
    suspend() {
      this.suspend = !this.suspend;
    },
  },
  created() {
    setInterval(() => {
      this.num++;
      if (this.num > 3) {
        clearInterval();
        this.num = 1;
      }
    }, 300);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
