import CryptoJS from 'crypto-js';

const AesDecrypt = (props) => {
  let key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AES_RES_KEY);
  let iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AES_IV);

  const decrypt = CryptoJS.AES.decrypt(props, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  });
  return decrypt.toString(CryptoJS.enc.Utf8);
};

export default AesDecrypt;
