import { createApp } from "vue";
import App from "./App.vue";
import router from "./router.js";
import "./styles/app.css";
import { createI18n } from 'vue-i18n';

const messages = {
  ko: require('@/locales/ko.json'),
  th: require('@/locales/th.json'),
};

const i18n = createI18n({
  locale: process.env.VUE_APP_DEFAULT_LOCALE,
  fallbackLocale: process.env.VUE_APP_FALLBACK_LOCALE,
  messages
});
const app = createApp(App);

// app.use(VCalendar, {}).use(router).mount("#app");.use(i18n)
app.use(router).use(i18n).mount("#app");
